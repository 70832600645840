import {useEffect} from "react";

const useOutsideAlerter = (ref, handler) => {
    useEffect(() => {
        function handleClickOutside(event) {
            if (!ref.current || ref.current.contains(event.target)) {
                return;
            }
            handler(event);
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        }
    }, [ref, handler]);
}

export default useOutsideAlerter;