import React, {useEffect, useRef, useState} from "react";
import {useRouter} from "next/router";
import Head from "next/head";
import {Navbar} from "react-bootstrap";
import dynamic from "next/dynamic";
import {jwtDecode} from "../helper/jwtHelper";

import useOutsideAlerter from "../helper/useOutsideAlerter";
import Link from "next/link";
import styles from '../styles/order.module.scss';
import {DEFAULT_LOCALES_LIST} from "../helper/constantHelper";
import {setInterComData} from "../helper/utilHelper";

const Header = dynamic(() => import('./header'));
const Footer = dynamic(() => import('./footer'));
const Order = dynamic(() => import('../pages/order'));
const MyOrders = dynamic(() => import('../pages/my-orders'));
const OrderDetail = dynamic(() => import('../pages/my-orders/order-details'));
const Invoice = dynamic(() => import('../pages/invoice'));
const Profile = dynamic(() => import('../pages/profile'));
const Balance = dynamic(() => import('../pages/balance'));
const Level = dynamic(() => import('../pages/level'));
const Referral = dynamic(() => import('../pages/referral'));
const Sidebar = dynamic(() => import('./order/sidebar'));
const NotLoginOrder = dynamic(() => import('../components/order/placeOrder/notLoginOrder'));

const Layout = ({ children }) => {

  const router = useRouter();
  const [isReadyRender, setIsReadyRender] = useState(false);
  const [coupenCode, setCoupenCode] = useState("");

  const beforeLoginPages = ['/login', '/signup', '/forget-password'];
  const afterLoginPages = ['/profile', '/balance', '/level', '/referral', '/my-orders', '/my-orders/order-details', '/order', '/invoice'];

  const { currLocale, hrefLang, linkLocale } = children.props;
  const localeHref = !currLocale || DEFAULT_LOCALES_LIST.includes(currLocale) ? "" : `/${currLocale}`;

  const expireToken = () => {
    if (!!window.Intercom && typeof window !== 'undefined') {
      window.Intercom('boot', {
        app_id: 'je6f9lsz'
      });
    }

    if (afterLoginPages.includes(pathname) && pathname !== '/order') {
      localStorage.removeItem('token');
      router.push({
        pathname: `/login`,
        query: { pathname },
      });
    }
  }

  useEffect(() => {
    if (!router.isReady) return;

    const pathname = router.pathname;
    const loginToken = localStorage.getItem("token");

    if (loginToken) {
      const convertedToken = JSON.parse(loginToken);
      const now = new Date();
      const decodeToken = convertedToken ? jwtDecode(convertedToken.value) : null;
      if (decodeToken?.exp && (decodeToken.exp * 1000) >= now.getTime() && now.getTime() <= convertedToken.expiry) {
        if (beforeLoginPages?.includes(pathname)) {
          router.push(`/order`, `/order`, { locale: currLocale });
        } else {
          if (!!window.Intercom && typeof window !== 'undefined') {
            window.Intercom('boot', {
              app_id: 'je6f9lsz'
            });
          }
        }
        setInterComData(convertedToken.value);
      } else {
        expireToken();
      }
    } else {
      expireToken();
    }

    if (router.query?.code) {
      setCoupenCode(router.query.code);
    } else if (router.query?.discount_code) {
      setCoupenCode(router.query.discount_code);
    }
    setIsReadyRender(true);

  }, [router.isReady]);

  const [changeProfile, setchangeProfile] = useState(false);
  const changeUserProfile = () => {
    setchangeProfile(!changeProfile);
  }

  const [isOpen, setIsOpen] = useState(false);
  // un comment line 99 and comment line 100 to display student bean banner
  // const [isStudentBeanOpen, setIsStudentBeanOpen] = useState(router.pathname === "/");
  const [isStudentBeanOpen, setIsStudentBeanOpen] = useState(false);

  const toggle = () => {
    document.body.classList.add(styles.headerSidebarOpen);
    setIsOpen(true);
  }

  const toggleClose = () => {
    document.body.classList.remove(styles.headerSidebarOpen);
    setIsOpen(false);
  }

  const getClassName = () => {
    if(router.pathname === "/referral" || router.pathname === "/level" || router.pathname === "/profile")
      return "width";
    else if(router.pathname === "/order" || router.pathname === "/my-orders" || router.pathname === "/balance")
      return "myOrdersM";
    return "";
  }

  const getComponentFromRouter = () => {
    switch(router.pathname){
      case "/profile":
        return <Profile changeUserProfile={changeUserProfile} meta={children.props.meta} currLocale={currLocale} />
      case "/balance":
        return <Balance changeUserProfile={changeUserProfile} meta={children.props.meta} currLocale={currLocale} />
      case "/level":
        return <Level meta={children.props.meta} currLocale={currLocale} />
      case "/referral":
        return <Referral meta={children.props.meta} currLocale={currLocale} />
      case "/my-orders":
        return <MyOrders meta={children.props.meta} currLocale={currLocale} />
      case "/my-orders/order-details":
        return <OrderDetail meta={children.props.meta} currLocale={currLocale} />
      case "/order":
        return <Order coupenCode={coupenCode} changeUserProfile={changeUserProfile} meta={children.props.meta} currLocale={currLocale} />
      case "/invoice":
        return <Invoice meta={children.props.meta} changeUserProfile={changeUserProfile} currLocale={currLocale} />
      default:
        return null;
    }
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => toggleClose());

  const pathname = router.pathname;
  const loginToken = isReadyRender ? localStorage.getItem("token") : null;

  const loginLayout = () => {
    return <>
      <div className={styles.order}>
        <div className={`top-navbar ${styles.orderMobileMenu} d-md-block d-lg-none`}>
          <Navbar expand="md" className={styles.navbar}>
            <div className="container">
              <Navbar.Brand href="/" className={styles.navbarBrand}>
                Cheapest <span>Essay</span>
              </Navbar.Brand>
              <Link href={`/logout`} className="btn secondary-btn" hidden>
                
                  Logout
                
              </Link>
            </div>
          </Navbar>
        </div>
        <Navbar.Toggle className={`${styles.navbarToggler} d-lg-none d-md-block`} onClick={toggle}>
          <div className={`${styles.navbarTogglerIcon} ${styles.open}`}>
            <span/>
            <span/>
            <span/>
          </div>
        </Navbar.Toggle>
        {isOpen &&
          <div className={styles.sidebarOverlay}>
            <div className={`${styles.orderLogin} m-0`} ref={wrapperRef}>
              <Sidebar
                  toggleClose={toggleClose}
                  changeProfile={changeProfile}
                  meta={children.props.meta}
                  currLocale={currLocale}
              />
              <div className={`${styles.navbarTogglerIcon} ${styles.close}`} onClick={toggleClose}>
                <span/>
                <span/>
                <span/>
              </div>
            </div>
          </div> 
        }

        {loginToken && (
            <div className={styles.orderLogin}>
              <Sidebar
                  toggleClose={toggleClose}
                  className="d-none d-lg-block"
                  changeProfile={changeProfile}
                  meta={children.props.meta}
                  currLocale={currLocale}
              />
              <div id={"orderingDetails"} className={`${styles.orderingDetails} ${getClassName()}`}>
                {getComponentFromRouter()}
              </div>
            </div>
        )}
      </div>
    </>;
  }

  const notLoginLayout = () => {
    return (
        <>
          <Header currLocale={currLocale} linkLocale={linkLocale}
                  isStudentBeanOpen={isStudentBeanOpen}
                  setIsStudentBeanOpen={setIsStudentBeanOpen}
                  sub_menu_data={children.props.sub_menu_data} />
          <div className={`bodyMain ${isStudentBeanOpen ? styles.bodyMain : ''}`}>
            <NotLoginOrder currLocale={currLocale} meta={children.props.meta} />
          </div>
          <Footer currLocale={currLocale} linkLocale={linkLocale} />
        </>
    )
  }

  const notAfterLoginPage = () => {
    switch (pathname) {
      case "/login":
      case "/signup":
      case "/forget-password":
      case "/file": {
        return children
      }
      default: {
        return <>
          <Header currLocale={currLocale}
                  isStudentBeanOpen={isStudentBeanOpen}
                  setIsStudentBeanOpen={setIsStudentBeanOpen}
                  linkLocale={linkLocale}
                  sub_menu_data={children.props.sub_menu_data} />
          <div className={`bodyMain ${isStudentBeanOpen ? styles.bodyMain : ''}`}>{children}</div>
          {router.route !== "/business-partnership" && <Footer currLocale={currLocale} linkLocale={linkLocale} /> }
        </>
      }
    }
  }

  const renderLayout = () => {
    let checkLogin = '';
    if (loginToken) {
      const convertedToken = JSON.parse(loginToken);
      const now = new Date();
      const decodeToken = convertedToken ? jwtDecode(convertedToken.value) : null;
      if (decodeToken?.exp && (decodeToken.exp * 1000) >= now.getTime() && now.getTime() <= convertedToken.expiry) {
        if (beforeLoginPages?.includes(pathname)) {
          router.push(`/order`, `/order`, { locale: currLocale });
        } else {
          checkLogin = 'true';
        }
      } else {
        checkLogin = 'false';
      }
    } else {
      checkLogin = 'false';
    }
    return checkLogin === 'true' ? loginLayout() : notLoginLayout();
  }

  const routerLinks = () => {
    if(router) {
      if (router.pathname === '/404') {
        return null;
      }
      else if (router.pathname.includes("post")) {
        return (
          <>
            <link rel="alternate" type="application/rss+xml" title="RSS Feed for CheapestEssay Articles" href={process.env.hostBaseUrl + '/articles/feed'} />
            <link rel="canonical" href={process.env.hostBaseUrl + router.asPath} />
          </>
        )
      }
      else if (router.query) {
        if (router.query.hasOwnProperty('serviceName')) {
          return (
            <>
              <link rel="canonical" href={process.env.hostBaseUrl + localeHref + "/" + router.query.serviceName} />
            </>
          )
        }
        else if (router.query.page && router.pathname.includes("articles")) {
          return (
            <>
              <link rel="alternate" type="application/rss+xml" title="RSS Feed for CheapestEssay Articles" href={process.env.hostBaseUrl + '/articles/feed'} />
              <link rel="canonical" href={process.env.hostBaseUrl + (router.asPath.includes("?") ? router.asPath.split("?")[0].toString() : router.asPath) + (router.query.page ? '?page=' + router.query.page : '')} />
            </>
          )
        }
        else if (router.pathname.includes("articles")) {
          return (
            <>
              <link rel="alternate" type="application/rss+xml" title="RSS Feed for CheapestEssay Articles" href={process.env.hostBaseUrl + '/articles/feed'} />
              <link rel="canonical" href={process.env.hostBaseUrl + router.asPath} />
            </>
          )
        }
        else if (router.pathname.includes("examples") || router.pathname.includes("tools")) {
          return (
            <>
              <link rel="canonical" href={process.env.hostBaseUrl + router.asPath} />
            </>
          )
        }
        else if (beforeLoginPages.some((item) => router.pathname.includes(item)) || afterLoginPages.some((item) => router.pathname.includes(item))) {
          return (
            <>
              <link data={router.pathname} rel="canonical" href={process.env.hostBaseUrl + (router.query?.user_name ? router.asPath : router.pathname)} />
            </>
          )
        }
        else {
          return (
            <>
              <link rel="canonical" href={process.env.hostBaseUrl + localeHref + (router.asPath === '/' ? '' : router.asPath)} />
            </>
          )
        }
      }
    }
  }

  return (
    <>
      {
        pathname && afterLoginPages?.includes(pathname) ? renderLayout() : notAfterLoginPage()
      }
      <Head>
        <link rel="apple-touch-icon" sizes="76x76" href={`/apple-touch-icon.png`} />
        <link rel="icon" type="image/png" sizes="32x32" href={`/favicon-32x32.png`} />
        <link rel="icon" type="image/png" sizes="16x16" href={`/favicon-16x16.png`} />
        <link rel="mask-icon" href={`/safari-pinned-tab.svg`} color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />

        <link rel="icon" type="image/png" sizes="192x192" href={`/android-icon-192x192.png`} />
        <link rel="icon" type="image/png" sizes="32x32" href={`/favicon-32x32.png`} />
        <link rel="icon" type="image/png" sizes="48x48" href={`/favicon-48x48.png`} />
        <link rel="icon" type="image/png" sizes="96x96" href={`/favicon-96x96.png`} />
        <link rel="icon" type="image/png" sizes="16x16" href={`/favicon-16x16.png`} />

        <link rel="apple-touch-icon" href={`/apple-icon-57x57.png`} />
        <link rel="apple-touch-icon" sizes="57x57" href={`/apple-icon-57x57.png`} />
        <link rel="apple-touch-icon" sizes="60x60" href={`/apple-icon-60x60.png`} />
        <link rel="apple-touch-icon" sizes= "72x72" href={`/apple-icon-72x72.png`} />
        <link rel="apple-touch-icon" sizes="76x76" href={`/apple-icon-76x76.png`} />
        <link rel="apple-touch-icon" sizes="114x114" href={`/apple-icon-114x114.png`} />
        <link rel="apple-touch-icon" sizes="120x120" href={`/apple-icon-120x120.png`} />
        <link rel="apple-touch-icon" sizes="144x144" href={`/apple-icon-144x144.png`} />
        <link rel="apple-touch-icon" sizes="180x180" href={`/apple-icon-180x180.png`} />

        <link rel="mask-icon" href={`/logo.svg`} color="orange" />
        <link rel="manifest" href="/manifest.json" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content={`/ms-icon-144x144.png`} />

        {routerLinks()}
        
        {hrefLang?.list?.length > 0 ?
          hrefLang.list.map((langItem, langIndex) => {
            return (
              <link key={langIndex} rel="alternate" hrefLang={langItem.code} href={langItem.url} />
            )
          })
        : ''}

      </Head>
    </>
  );
}

export default Layout;